<template>
  <div class="commonMain" :class="objclass" v-if="wendalistArr.length > 0">
    <div class="zixunitem" v-for="(item,index) in wendalistArr" :key="index" @click="todetail(item)">
      <!-- 问 -->
      <div class="zixuntop flexbox">
        <img src="@/assets/img/wen.png" alt="">
        <span class="line1">{{item.content}}</span>
      </div>
      <!-- 类型 地址 时间 -->
      <div class="typeaddr flex_between" v-if="type == 2 || type == 3">
        <div class="flexbox">
          <p class="lei" v-if="item.fenglei">{{item.fenglei}}</p>
          <p class="di">{{item.yuejianaddr}}</p>
        </div>
        <p class="shi">{{item.createTime}}</p>
      </div>
      <!-- 律师答 -->
      <div v-if="item.lsFuwuOrderMsgs">
      <div class="dabox" v-if="type == 1 || type == 3" >
        <div class="flexbox topda" v-if="item.lsLawyer">
          <img :src="item.lsLawyer.photo" alt="">
          <span>{{item.lsLawyer.lawyerName}}律师</span>
          <span>{{item.lsLawyer.addr}}</span>
        </div>
        <div class="daitem" v-if="item.lsFuwuOrderMsgs.length > 0">
          <div class="topdaitem flexbox" >
            <img src="@/assets/img/da.png" alt="">
            <span class="line1" v-if="item.lsFuwuOrderMsgs[0].content">{{item.lsFuwuOrderMsgs[0].content}}</span>
          </div>
          <p>{{item.lsFuwuOrderMsgs[0].createTime | fifterTime}}</p>
        </div>
      </div>
    </div>

    </div>

    <!-- 更多问题 -->
    <div class="morewen flexbox" v-if="more" @click.stop="tourl">更多问题 
      <img src="@/assets/img/right.png" alt="">
    </div>
    
  </div>
</template>

<script>
import resetTime from "@/util/check.js";
export default {
  name: '',
  components: {},
  props: {
    /**
     * 1 首页 问律师答 
     * 2 法律咨询首页 最新咨询  （问，类型和地址）
     * 3 法律咨询首页 最新回复  （问，类型和地址，律师信息 ，答） 
     * 
     * 
     */ 
    type: {
      type: Number,
      default: 1
    },
    /***
     * 要跳转的路径
     * 
     * 
     */ 
    url: {
      type: String,
      default: ''
    },
    /***
     * 是否显示  更多
     */ 
     more: {
      type: Boolean,
      default: false
    },
    /***
     * 解答列表
     */ 
     wendalistArr: {
      type: Array,
      default: (()=>[])
    },
 

  },
  data() {
    return {
  
      objclass:{
        typeclass1: this.type == 1,
        typeclass2: this.type == 2 || this.type == 3,
        typeclass4: this.type == 4,
      }
    }
  },
  filters: {
			fifterTime(value) {
				let time = value ? value.replace(/-/g, '/') : '';
				if (time) {
					return resetTime.timeago(new Date(time).getTime());
				}
			}
		},

  mounted() {
   
  },

  methods: {
      // 跳转
      tourl() {
        // let url = '/' + this.url
        this.$router.push({
          path: this.url
        })
      },
      // 详情
      todetail(data){

       if (window.location.hash.indexOf('/wendetails?id') > -1) {
          this.$router.push({
            path: '/wendetails?id=' + data.id
          })
          this.$emit('refresh')
        } else {
          this.$router.push({
            path: '/wendetails?id=' + data.id
          })
        }
      }
      
  }
}
</script>

<style lang="scss">

.zixunitem{
    padding-top: 20px;
    padding-bottom: 27px;
    margin-bottom: 10px;
    border-bottom: 1px solid #EEEEEE;
    cursor: pointer;

    .zixuntop{
      img{
        width: 22px;
        height:22px;
        margin-right:12px;
      }
      span{
        width: 658px;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
      }
    }
    .dabox{
      margin-top: 17px;
      .topda{
        img{
          width: 48px;
          height: 48px;
          border-radius: 50%;
          margin-right: 12px;
        }
        span:nth-child(2){
          font-size: 18px;
          font-weight: 500;
          color: #333333;
          margin-right: 12px;
        }
        span:nth-child(3){
          font-size: 15px;
          color: #777777;
        }
      }
      .daitem{
        margin-left: 50px;
        // width: 642px;
        height: 82px;
        background: #F5F5F5;
        border-radius: 0px 13px 13px 13px;
        background-color: #ECECEC;
        .topdaitem{
          padding: 16px;
          img{
            width: 22px;
            height: 22px;
            margin-right: 12px;
          }
          span{
            display: inline-block;
            width: 658px;
            font-size: 13px;
            color: #333333;
          }
        
        }
        p{
            text-align: right;
            font-size: 14px;
            color: #777777;
            padding-right: 16px;
          }
      }
    }
  }

  // 最新咨询
  .typeclass2{
  
    .zixunitem:nth-last-child(1){
      border-bottom: 1px solid transparent !important;
    }
    .zixunitem:last-child{
      border-bottom: 1px solid transparent !important;
    }
   
    .typeaddr{
      margin-top: 16px;
      padding-left: 30px;
      .lei{
        border-radius: 17px;
        border: 1px solid #CCCCCC;
        padding: 4px 16px;
        font-size: 14px;
        color: #777777;
        margin-right: 20px;
      }
      .di{
        font-size: 14px;
        color: #777777;
      }
      .shi{
        font-size: 14px;
        color: #777777;
      }
    }
  }



  // 更多问题
  .morewen{
    font-size: 16px;
    color: #777777;
    // margin-top: 30px;
    cursor: pointer;
    img{
      width: 22px;
      height: 22px;
      margin-left: 4px;
    }
  }


</style>
<template>
  <div class="no-data">
    <img src="@/assets/img/empty.png" alt="">
    <p>暂无数据</p>
  </div>
</template>

<script>
export default {
  name: 'no-data'
}
</script>

<style lang='scss' scoped>
.no-data {
  text-align: center;
  font-size: 20px;
  color: #999;
  margin: 50px 0;
  img {
    // width: 240px;
    height: 180px;
    margin-bottom: 30px;
  }
}
</style>
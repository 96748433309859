<template>
  <div class="lydetails">
      <div class="introduce">
        <div class="base_w">
          <p class="subtitle">{{ClassaInfo.parentName}}</p>
          <div class=""> <span> {{ClassaInfo.pdetails | filtersText }}  </span>  <span @click.stop="showdetail" class="colorspan">详情</span></div>
        </div>
      </div>

      <!-- 提问 -->
      <div class="lycenter base_w flex">
        <!-- style="display: flex;flex-direction: column;justify-content: space-between;"  -->
        <div class="common_index_leftwidth">
          <div class="lyspanbox flexbox">
            <a 
            href='javascript:;' 
            v-for="(item,i) in sublist" :key="i" 
            :class="[spanItem == i ? 'active' : '']"
            @click.stop="spanItemFun(item,i)"
            >{{item.name}}</a>
          </div>
          <div>
            <div class="common_title_box">
            <span class="title">提问</span>
            </div>
            <div class="tiwenbox">
              <zixun :type="2" ref="zixunwen" :title="ClassaInfo.parentName"></zixun>
            </div>
            </div>
        
          </div>
        <div class="common_index_rightwidth">
          <div class="common_title_box">
            <span class="title">最新评价</span>
          </div>
          <pingjia ref="newpingjia" :list="pingjialist" :height="2"></pingjia>
          <NoData v-if="pingjialist.length <= 0"></NoData>
        </div>
      </div>

      <!-- 相关咨询 -->
      <div class="xgbox base_w flex">
        <div class="common_index_leftwidth">
          <div class="common_title_box">
            <span class="title">相关咨询</span>
            <div class="righttext" @click.stop="tourl('/typedetails?fenglei=' + ClassaInfo.parentName)">
                更多
                <img src="@/assets/img/right.png" alt="" class="iconimg">
            </div>
          </div>
          <wenda ref="newwenda" :wendalistArr="wendalist"></wenda>
          <NoData v-if="wendalist.length <= 0"></NoData>
        </div>
        <div class="common_index_rightwidth">
          <div class="common_title_box">
            <span class="title">最新订单</span>
          </div>
          <div class="orderbox">
            <div class="orderitem flex_between" v-for="(item,i) in orderlist" :key="i">
              <div class="itemleft flexbox">
                <img :src="item.lsLawyer.photo" alt="" v-if="item.lsLawyer">
                <span class="name" v-if="item.lsLawyer">{{item.lsLawyer.lawyerName}}律师</span>
                <p v-if="item.price > 0">￥{{item.price}}</p>
                <span>{{item.type}}</span>
              </div>
              <p class="itemright">{{item.createTime | fifterTime}}</p>
            </div>
            <NoData v-if="orderlist.length <= 0"></NoData>
          </div>
        </div>
      </div>

      <lydetailDia ref="lydetaildia"></lydetailDia>

  </div>
</template>

<script>
import zixun  from '@/components/zixun'
import pingjia  from '@/components/pingjia'
import wenda  from '@/components/wenda'
import lydetailDia  from '@/components/lydetailDia'
import NoData  from '@/components/NoData'

import resetTime from "@/util/check.js";

import apiUrl from '@/api/main'
export default {
  components: {zixun,pingjia,wenda,lydetailDia,NoData},
  data() {
      return {
        spanItem:'', // 提问选中
        lsFuwuinfo:{},
        sublist:[], // 二级标签
        ClassaInfo:{}, // 父级内容
        wendalist:[], // 咨询
        pingjialist:[], // 最新评价
        orderlist:[], // 最新订单
        subobj:{}, // 选中的子标签内容

      };
  },
  filters:{
    // 富文本转文本
    filtersText(val){
        if (val != null && val != '') {
            let reg = /[\u4e00-\u9fa5]/g;
            let names = val.match(reg);
            val = names ? names.join('') : '';
            return val;
        } else return '';
    },
    // 时间
    fifterTime(value) {
				let time = value ? value.replace(/-/g, '/') : '';
				if (time) {
					return resetTime.timeago(new Date(time).getTime());
				}
			}
},
  computed: {},
  watch: {},
  mounted() {
    this.getlist(); // 获取服务详情
    // this.getpingjialist(); // 最新评价和 订单
    // 跳转回顶部
    document.documentElement.scrollTop = 0;
  },
  methods: {
    tourl(url){
      this.$router.push({
        path: url
      })
    },
    spanItemFun(data,val){
      this.spanItem = val;
      this.subobj = data;
    },
    // 详情显示
    showdetail(){
      this.$refs.lydetaildia.open(this.ClassaInfo);
    },
    // 法律知识列表 详情
    getlist(){
      let data = {
				userId: localStorage.getItem('userId'),
        pId:this.$route.query.id,
      }
      apiUrl.lsLawKnowledgelist(data).then((res) => {
        this.sublist = res.rows;
        if(res.rows.length > 0){
          this.ClassaInfo = {
            parentName: res.rows[0].parentName,
            pdetails:res.rows[0].pdetails,
          }
        }
        res.rows.forEach((item,i) => {
          if(item.id  == this.$route.query.subid){
            this.spanItem = i;
            this.subobj.name = item.name;
          }
        });

        this.getwendalist(); // 咨询
        this.getpingjialist(); // 最新评价和 订单

      })
    },

    // 获取咨询
    getwendalist() {
      let data = {
        fenglei:this.ClassaInfo.parentName,
				pageSize: 5,
				pageNum: 1,
      }
      apiUrl.wendalist(data).then((res) => {
          this.wendalist = res.rows;
      })
    },

     // 获取最新评价
     getpingjialist() {
      let data = {
        // type: this.subobj.name,
        // type:this.ClassaInfo.parentName,
				// pageSize: 5,
				// pageNum: 1,
      }
      apiUrl.zuixinorderlist(data).then((res) => {
          this.pingjialist = res.rows;
          this.orderlist = res.rows;
          this.$refs.newpingjia.open();
      })
    },

 
  },
 
 

  }
</script>
<style lang='scss'>

.lydetails{
  background-color: #fff;
  padding-bottom: 100px;
}
// 热门领域介绍
.introduce{
  background: #F8F8F8;
  padding:24px 0;
  .base_w{
    background: url('../../assets/img/bg2.png') no-repeat;
    background-size: 100% 100%;
    padding: 40px;
    p.subtitle{
      font-size: 24px;
      font-weight: 500;
      color: #662D12;
      margin-bottom: 16px;
    }
    div{
        font-size: 16px;
        font-weight: 400;
        color: #745647;
        line-height: 26px;
        p{

        }
        .colorspan{
          color: #305BFE;
          margin-left: 15px;
          cursor: pointer;
        }
    }
  }
}

// 提问
.lycenter{
  padding: 33px 0;
  border-bottom: 1px solid #EEEEEE;
  .lyspanbox{
    flex-wrap: wrap;
    a{
      border-radius: 20px;
      border: 1px solid #CCCCCC;
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      padding: 9px 20px;
      margin-right: 16px;
      margin-bottom: 24px;
    }
    .active,
    a:hover{
      border: 1px solid #305BFE;
      color: #305BFE;
      cursor: pointer;
    }
   
  }
  .tiwenbox{
    margin-top: 24px;
  }



}

// 相关咨询
.xgbox{
  margin-top: 41px;
}

// 最新订单
.orderbox{
  margin-top: 24px;
  .orderitem{
    background-color:  #F8F8F8;
    border-radius: 8px;
    padding: 16px 24px;
    margin-bottom: 20px;
    .itemleft{
      img{
        width: 48px;
        height: 48px;
        border-radius: 50%;
        margin-right: 16px;
      }
      .name{
        font-size: 18px;
        font-weight: 500;
        color: #333333;
        margin-right: 32px;
      }
      span{
        font-size: 15px;
        font-weight: 400;
        color: #333333;
      }
      p{
        font-size: 18px;
        color: #EF0000;
        margin-right: 13px;
      }
    }
    .itemright{
      font-size: 14px;
      font-weight: 400;
      color: #777777;
    }
   
  }
}
</style>
<template>
  <div class="lydetailDia">
    <el-dialog
        :title="title"
        :visible.sync="dialogVisible"
        width="500px"
        :before-close="handleClose">
        <div v-html="pdetails" class="detailbox"></div>
        <div slot="footer" class="dialog-footer-g">
          <span @click="dialogVisible = false">好的，我知道了</span>
        </div>
      </el-dialog>
  </div>
</template>

<script>
export default {
  name: '',
  components: {},
  props: {
  
  },
  data() {
    return {
      dialogVisible:false,
      title:'',
      pdetails:''
    }
  },

  mounted() {
   
  },

  methods: {
    open(ClassaInfo){
      this.dialogVisible = true;
      this.title = ClassaInfo.parentName;
      this.pdetails = ClassaInfo.pdetails;
    },
    handleClose(){
      this.dialogVisible = false;
    }
  }
}
</script>

<style lang="scss">
.lydetailDia{
  .detailbox{
  min-height: 200px;
  overflow: auto;
}
.dialog-footer-g{
  cursor: pointer;
  text-align: center;
  border-top: 1px solid #ECECEC;
  padding-top: 27px;
  span{
    font-size: 16px;
    color: #305BFE;
  }
}
}


</style>
<template>
  <div class="commonMain" v-if="list.length > 0" >
    <div class="mainpingjia" :class="[height==2?'height2' : 'height1']">
    <div class="pjbox" :class="{'animate-up': animateUp}">
      <div class="pjitem" v-for="(item,i) in list" :key="i">
        <div class="pjtop flexbox">
          <img :src="item.userPhoto" alt="">
          <span class="line1">{{item.content}}</span>
        </div>
        <div class="pjbottom flex_between">
          <el-rate v-model="item.pingjiaStar" disabled></el-rate>
          <span>{{item.createTime}}</span>
        </div>
      </div>
    </div>
  </div>
  </div>
</template>

<script>
export default {
  name: '',
  components: {},
  props: {
    list: {
      type: Array,
      // default: (()=>[])
      default: (()=>[])

    },
    height:{
      type: Number,
      default:1
    }
  },
  data() {
    return {
      // rateval:5,
      animateUp: false,
      timer: null
    }
  },

  mounted() {
      
  },

  methods: {
    open(){
      this.timer = setInterval(this.scrollAnimate, 1500);
    },
    
    scrollAnimate() {
      this.animateUp = true
      setTimeout(() => {
        this.list.push(this.list[0])
        this.list.shift()
        this.animateUp = false;
      }, 500)
    }
  },
  destroyed() {
    clearInterval(this.timer)
  }

}
</script>

<style lang="scss">

.mainpingjia{
  margin-top: 20px;
  overflow: hidden;
}
.height1{
  height: 490px;
}
.height2{
  // height: 296px;
  height: 358px;
}
 .pjbox{
    .pjitem{
      padding: 16px 24px 19px;
      border-radius: 8px;
      border: 1px solid #F1F1F1;
      margin-bottom: 12px;

      .pjtop{
        img{
          width: 48px;
          height: 48px;
          border-radius: 50%;
          margin-right: 12px;
        }
        span{
          display: inline-block;
          font-size: 16px;
          color: #333333;
        }
      }
      .pjbottom{
        padding-left: 59px;
        span{
          font-size: 14px;
          color: #777777;
        }
      }
    }
  }
  .animate-up {
    transition: all 1s ease-in-out;
    // transition: all 1s linear;
    transform: translateY(-92px);
  }

</style>